<template>
  <div
    :class="[
      positionClass,
      filtersOverlayClasses,
      { 'spinner-top': spinnerPosition === 'top' }
    ]">
    <div>
      <loading-spinner class="mx-auto" />
      <transition
        name="text"
        appear>
        <p
          v-if="text"
          class="typeset-9 text-ink mt-3 mb-0 text-center">
          {{ text }}
        </p>
      </transition>
      <div
        v-if="useProgressBar"
        class="mx-auto mt-3 br-3 relative overflow-hidden overlay-progress-bar-background bg-paper-5">
        <div
          class="absolute top-0 left-0 h-full w-full overlay-progress-bar"
          :class="progressBarClasses"
          :style="{ transform: `scaleX(${progressBarValue}%)` }"></div>
      </div>
      <transition
        name="text"
        appear>
        <p
          v-if="secondaryText"
          class="typeset-8 text-ink mt-3 mb-0 text-center secondary-text">
          {{ secondaryText }}
        </p>
      </transition>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "./LoadingSpinner.vue";

export default {
  name: "Overlay",
  components: {
    LoadingSpinner
  },
  props: {
    absolutelyPositioned: {
      type: Boolean,
      default: false
    },
    isDrawerOverlay: {
      type: Boolean,
      default: false
    },
    spinnerPosition: {
      type: String,
      default: "center",
      validator: value => {
        return ["center", "top"].indexOf(value) !== -1;
      }
    },
    text: {
      type: String,
      default: null
    },
    secondaryText: {
      type: String,
      default: null
    },
    useProgressBar: {
      type: Boolean,
      default: false
    },
    progressBarValue: {
      type: Number,
      default: 0
    },
    progressBarColour: {
      type: String,
      default: "sunflower"
    }
  },
  data() {
    return {
      animateProgressBar: false
    };
  },
  computed: {
    positionClass() {
      return this.absolutelyPositioned ? "absolute" : "fixed";
    },
    filtersOverlayClasses() {
      if (this.isDrawerOverlay) {
        return "spinner-overlay-filters";
      }
      return "spinner-overlay";
    },
    progressBarClasses() {
      return {
        animated: this.animateProgressBar,
        [`bg-${this.progressBarColour}`]: true
      };
    }
  },
  watch: {
    progressBarValue(newValue, oldValue) {
      this.animateProgressBar = newValue >= oldValue;
    }
  }
};
</script>

<style lang="scss" scoped>
.text-enter-from,
.text-leave-to {
  opacity: 0;
  transform: translate3d(0, 8px, 0);
}

.text-enter-active,
.text-leave-active {
  transition: all 0.2s ease-out;
}

.text-enter-to,
.text-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.overlay-progress-bar-background {
  height: 8px;
  width: 200px;
}

.secondary-text {
  width: 300px;
}

.overlay-progress-bar {
  transform-origin: left;

  &.animated {
    transition: transform 0.25s ease-in-out;
  }
}
</style>
